import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const vollibre = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Vol libre" />
    <h3 className='underline-title'>Vol libre</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Aé mbi 'ya me! (1)<br />
        Les vautours tourbillonnent au-dessus d'un endroit<br />
        Il doit s'y trouver quelque charogne<br />
        Je guette le mouvement de l'herbe <br />
        Elle ne danse plus au tam-tam du vent <br />
        Je respire de soulagement mérité <br />
        Après ce combat essoufflant et inégal <br />
        Le bruit glorieux distrait les traqueurs <br />
        Je pense à la maison de Kinshasa<br />
        À l'avenue de Loango <br />
        Aux coins opposés de l'avenue<br />
        Où se postaient des penauds traquenards <br />
        Ils croyaient aux intrigues des mouchards <br />
        Bukavu s'imposa en juge équitable <br />
        En étirant l'espace désarmant <br />
        La chaîne enfin rompue aux pattes<br />
        La cage-Bastille s'ouvrit<br />
        L'oiseau encagé s'envola <br />
        En vol libre dans l'air libre <br />
        Aé mbi 'ya me!<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Ah moi! = Cri de soulagement ou de misère.</p>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default vollibre
